import React from "react";
import { InView } from "react-intersection-observer";
import Header from "./Header";
import Footer from "./Footer";
import Title from "./Title";
import Highlight from "./Highlight";
import Textbox from "./Textbox";
import Image from "./Image";
import MoreProjects from "./MoreProjects";
import "../css/App.scss";
import ghostGamePlay from "../images/ghost_gameplay.gif";
import ghostCreate from "../images/ghost_create.gif";
import ghostProfile from "../images/ghost_profile.png";
import ghostLeaderboard from "../images/ghost_leaderboard.png";
import ghostGamePlay2 from "../images/ghost_endround.gif";
import ghostLayoutOld from "../images/ghost_layout_old.png";
import ghostLayoutNew from "../images/ghost_layout_new.png";
import ghostScreenOld from "../images/ghost_screen_old.png";
import ghostScreenNew from "../images/ghost_screen_new.png";
import ghostGradient from "../images/ghost_gradient.png";

function Ghost({ location }) {
  return (
    <div className="project-page" id="ghost-page">
      <Header />
      <Title section="project" text="ghost" location={location} viewed />

      <div className="content-container overview">
        <div className="sub-container column overview">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="blurb" delayN={0} topY={0} viewed={inView}>
                  A nostalgic multi-player online word game for friends.
                </Textbox>

                <div className="roles-container">
                  <Highlight section="role" delayN={1} topY={0} viewed={inView}>
                    game design
                  </Highlight>
                  <Highlight section="role" delayN={2} topY={0} viewed={inView}>
                    ui/ux design
                  </Highlight>
                </div>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container column overview">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <div className="sub-container column overview">
                  <Highlight
                    section="label"
                    delayN={2}
                    topY={0}
                    viewed={inView}
                  >
                    date
                  </Highlight>
                  <Textbox
                    id="metadata right-align"
                    delayN={3}
                    topY={-20}
                    viewed={inView}
                  >
                    January 2018
                  </Textbox>
                </div>
              </div>
            )}
          </InView>
        </div>
      </div>

      <InView triggerOnce>
        {({ inView, ref }) => (
          <div ref={ref}>
            <Title
              section="project-section"
              text="the background"
              delayN={0}
              viewed={inView}
            />
          </div>
        )}
      </InView>

      <div className="sub-container section">
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight section="banner" delayN={0} topY={0} viewed={inView}>
                  a childhood game
                </Highlight>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="big" delayN={0} topY={0} viewed={inView}>
                  <a
                    href="https://www.britannica.com/topic/ghosts-word-game"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ghost
                  </a>{" "}
                  is a game that I enjoyed playing with friends as a child.
                  <br />
                  <br />
                  The rules are simple. Players take turns adding one letter
                  each to the end of a growing string. Complete a word, and you
                  get one strike. Five strikes and you become a ghost!
                  <br />
                  <br />
                  The beauty of the game is in its <b>simplicity</b> — it's easy
                  to learn, and experienced players don't have a huge advantage
                  against beginners.
                </Textbox>
              </div>
            )}
          </InView>
        </div>
      </div>

      <div className="sub-container section">
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight section="banner" delayN={0} topY={0} viewed={inView}>
                  make it digital
                </Highlight>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="big" delayN={0} topY={0} viewed={inView}>
                  Ghost is traditionally played with pen and paper, or simply by
                  speaking out loud.
                  <br />
                  <br />
                  This nostalgic game is already fun to play. However, there was
                  the opportunity to use the <b>
                    strengths of digital games
                  </b>{" "}
                  to enhance it in various ways.
                  <br />
                  <br />
                  This project was a collaboration with my friends Lillian Bu
                  and Calvin Phung. I led UI, UX, and graphic design, and worked
                  on the frontend.
                </Textbox>
              </div>
            )}
          </InView>
        </div>
      </div>

      <InView triggerOnce>
        {({ inView, ref }) => (
          <div ref={ref}>
            <Title
              section="project-section"
              text="the game"
              delayN={0}
              viewed={inView}
            />
          </div>
        )}
      </InView>

      <div className="sub-container section">
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight section="banner" delayN={0} topY={0} viewed={inView}>
                  a game for friends
                </Highlight>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="big" delayN={0} topY={0} viewed={inView}>
                  One of the benefits of digital games is the ability to play
                  with friends <b>remotely</b>.
                  <br />
                  <br />A player can start a game, or join an existing room
                  using a code.
                </Textbox>
              </div>
            )}
          </InView>
        </div>
        <InView triggerOnce>
          {({ inView, ref }) => (
            <div
              ref={ref}
              className="sub-container buffered-h buffered-v centered-h"
            >
              <Image
                source={ghostCreate}
                id="ghost-sections"
                delayN={0}
                topY={0}
                viewed={inView}
              />
            </div>
          )}
        </InView>
      </div>

      <div className="sub-container section">
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight section="banner" delayN={0} topY={0} viewed={inView}>
                  visual cues
                </Highlight>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="big" delayN={0} topY={0} viewed={inView}>
                  <b>Name bubbles</b> on the screen indicate player order.
                  <br />
                  <br />
                  The background scrolls higher into the night sky to enhance
                  the feeling of accomplishment, as well as suspense, as the
                  string grows longer.
                  <br />
                  <br />
                  Letters under a player&apos;s bubble indicate how many strikes
                  they have.
                </Textbox>
              </div>
            )}
          </InView>
        </div>
        <InView triggerOnce>
          {({ inView, ref }) => (
            <div
              ref={ref}
              className="sub-container buffered-h buffered-v centered-h"
            >
              <Image
                source={ghostGamePlay}
                id="ghost-sections"
                delayN={0}
                topY={0}
                viewed={inView}
              />
            </div>
          )}
        </InView>
      </div>

      <div className="sub-container section">
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight section="banner" delayN={0} topY={0} viewed={inView}>
                  automated features
                </Highlight>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="big" delayN={0} topY={0} viewed={inView}>
                  Player order is <b>randomized</b> for each round, to keep
                  things fair.
                  <br />
                  <br />
                  Words are <b>automatically checked</b> for validity using the{" "}
                  <a
                    href="https://www.britannica.com/topic/ghosts-word-game"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Datamuse API
                  </a>
                  , so there are no interruptions to gameplay caused by
                  disagreements about whether a letter is valid.
                </Textbox>
              </div>
            )}
          </InView>
        </div>
      </div>

      <div className="sub-container section">
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight section="banner" delayN={0} topY={0} viewed={inView}>
                  the power of data
                </Highlight>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="big" delayN={0} topY={0} viewed={inView}>
                  Players sign in using Google OAuth.
                  <br />
                  <br />A player&apos;s number of wins, number of games played,
                  and longest string of letters are saved to their account so
                  they can try to beat their records, or just know for fun.
                  <br />
                  <br />
                  Collecting global data from online gameplay enables the
                  creation of a <b>leaderboard</b> that serves as motivation to
                  play. It also provides a <b>sense of community</b> around the
                  game.
                </Textbox>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container centered-h">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref} className="sub-container buffered-v buffered-h">
                <Image
                  source={ghostProfile}
                  id="ghost-sections"
                  delayN={0}
                  topY={0}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref} className="sub-container buffered-v buffered-h">
                <Image
                  source={ghostLeaderboard}
                  id="ghost-sections"
                  delayN={0}
                  topY={0}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
        </div>
      </div>

      <div className="sub-container section">
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight section="banner" delayN={0} topY={0} viewed={inView}>
                  delight
                </Highlight>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="big" delayN={0} topY={0} viewed={inView}>
                  Users that are “out” turn into a cute ghost, and can roam
                  around to try and distract the other players.
                  <br />
                  <br />
                  Smooth and visually appealing animations enhance gameplay.
                </Textbox>
              </div>
            )}
          </InView>
        </div>
        <InView triggerOnce>
          {({ inView, ref }) => (
            <div
              ref={ref}
              className="sub-container buffered-h buffered-v centered-h"
            >
              <Image
                source={ghostGamePlay2}
                id="ghost-sections"
                delayN={0}
                topY={0}
                viewed={inView}
              />
            </div>
          )}
        </InView>
      </div>

      <InView triggerOnce>
        {({ inView, ref }) => (
          <div ref={ref}>
            <Title
              section="project-section"
              text="the layout"
              delayN={0}
              viewed={inView}
            />
          </div>
        )}
      </InView>

      <div className="sub-container section">
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="big" delayN={0} topY={0} viewed={inView}>
                  The layout was designed to make gameplay intuitive through
                  visual cues, and to concentrate the viewer&apos;s gaze to a
                  centralized area.
                  <br />
                  <br />
                  It went through a couple iterations to be optimized.
                </Textbox>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container centered-h">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div
                ref={ref}
                className="sub-container caption-image buffered-v ghost-compare"
              >
                <Image
                  source={ghostLayoutOld}
                  id="ghost-sections"
                  delayN={0}
                  topY={0}
                  viewed={inView}
                />
                <div className="caption-container">
                  <Highlight
                    section="image-label"
                    delayN={1}
                    topY={0}
                    viewed={inView}
                  >
                    initial
                  </Highlight>
                </div>
              </div>
            )}
          </InView>
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div
                ref={ref}
                className="sub-container caption-image buffered-v ghost-compare"
              >
                <Image
                  source={ghostLayoutNew}
                  id="ghost-sections"
                  delayN={0}
                  topY={0}
                  viewed={inView}
                />
                <div className="caption-container">
                  <Highlight
                    section="image-label"
                    delayN={1}
                    topY={0}
                    viewed={inView}
                  >
                    final
                  </Highlight>
                </div>
              </div>
            )}
          </InView>
        </div>
      </div>

      <InView triggerOnce>
        {({ inView, ref }) => (
          <div ref={ref}>
            <Title
              section="project-section"
              text="the art"
              delayN={0}
              viewed={inView}
            />
          </div>
        )}
      </InView>

      <div className="sub-container section">
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="big" delayN={0} topY={0} viewed={inView}>
                  The visual aspects of the game were iterated on in order to
                  create the most delightful and playful atmosphere for the
                  players.
                  <br />
                  <br />
                  The final illustration style is minimal with a flair, and has
                  a rounded, bubbly theme.
                </Textbox>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container centered-h">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div
                ref={ref}
                className="sub-container caption-image buffered-v ghost-compare"
              >
                <Image
                  source={ghostScreenOld}
                  id="ghost-sections"
                  delayN={0}
                  topY={0}
                  viewed={inView}
                />
                <div className="caption-container">
                  <Highlight
                    section="image-label"
                    delayN={1}
                    topY={0}
                    viewed={inView}
                  >
                    initial
                  </Highlight>
                </div>
              </div>
            )}
          </InView>
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div
                ref={ref}
                className="sub-container caption-image buffered-v ghost-compare"
              >
                <Image
                  source={ghostScreenNew}
                  id="ghost-sections"
                  delayN={0}
                  topY={0}
                  viewed={inView}
                />
                <div className="caption-container">
                  <Highlight
                    section="image-label"
                    delayN={1}
                    topY={0}
                    viewed={inView}
                  >
                    final
                  </Highlight>
                </div>
              </div>
            )}
          </InView>
        </div>
        <InView triggerOnce>
          {({ inView, ref }) => (
            <div
              ref={ref}
              className="sub-container buffered-h buffered-v centered-h"
            >
              <Image
                source={ghostGradient}
                id="ghost-gradient"
                delayN={0}
                topY={0}
                viewed={inView}
              />
            </div>
          )}
        </InView>
      </div>

      <InView triggerOnce>
        {({ inView, ref }) => (
          <div ref={ref}>
            <Title
              section="project-section"
              text="the award"
              delayN={0}
              viewed={inView}
            />
          </div>
        )}
      </InView>

      <div className="sub-container section">
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="big" delayN={0} topY={0} viewed={inView}>
                  Ghost was awarded <b>Honorable Mention</b> in{" "}
                  <a
                    href="https://weblab.mit.edu/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    MIT Web Lab
                  </a>{" "}
                  by judges from Google, Facebook, and other top tech companies.
                </Textbox>
              </div>
            )}
          </InView>
        </div>
      </div>

      <MoreProjects hide="ghost" />
      <Footer />
    </div>
  );
}

export default Ghost;
