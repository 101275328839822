import { AnimatePresence, AnimateSharedLayout } from "framer-motion"
import React, { useEffect } from "react"
import ReactGA from 'react-ga4'
import { useHistory } from "react-router-dom"
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom"
import "../css/App.scss"
import Bars from "./Bars"
import Ghost from "./Ghost"
import Guitar from "./Guitar"
import Home from "./Home"
import KoodosArt from "./KoodosArt"
import KoodosCase from "./KoodosCase"
import Language from "./Language"
import MIC from "./MIC"
import Rivet from "./Rivet"
import ScrollToTop from "./ScrollToTop"
import Teachbot from "./Teachbot"
import TechX from "./TechX"
import Xfair from "./Xfair"

const AnimatedSwitch = withRouter(({ location }) => {
  const history = useHistory()
  useEffect(() => {
    window.onpopstate = () => {
      window.location.reload()
    }
  }, [])
  useEffect(() => {
    const unregister = history.listen((location) => {
      ReactGA.set({ page: location.pathname });
      ReactGA.send(["pageview", location.pathname]);
    });
    return unregister
  }, [history])
  return (
    <AnimateSharedLayout type="crossfade">
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route exact path="/" render={() => <Home location={location} />} />
          <Route
            exact
            path="/projects/ghost"
            render={() => <Ghost location={location} />}
          />
          <Route
            exact
            path="/projects/language-of-color"
            render={() => <Language location={location} />}
          />
          <Route
            exact
            path="/projects/koodos-case"
            render={() => <KoodosCase location={location} />}
          />
          <Route
            exact
            path="/projects/guitar"
            render={() => <Guitar location={location} />}
          />
          <Route
            exact
            path="/projects/koodos-art"
            render={() => <KoodosArt location={location} />}
          />
          <Route
            exact
            path="/projects/bars-of-weather"
            render={() => <Bars location={location} />}
          />
          <Route
            exact
            path="/projects/xfair"
            render={() => <Xfair location={location} />}
          />
          <Route
            exact
            path="/projects/mic"
            render={() => <MIC location={location} />}
          />
          <Route
            exact
            path="/projects/techx-apply"
            render={() => <TechX location={location} />}
          />
          <Route
            exact
            path="/projects/teachbot"
            render={() => <Teachbot location={location} />}
          />
          <Route
            exact
            path="/projects/rivet"
            render={() => <Rivet location={location} />}
          />
        </Switch>
      </AnimatePresence>
    </AnimateSharedLayout>
  )
})

const TRACKING_ID = "G-6R4MTS4K3H";

export default function App() {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
  }, [])
  return (
    <BrowserRouter>
      <ScrollToTop />
      <AnimatedSwitch />
    </BrowserRouter>
  )
}
