import React from "react";
import { motion } from "framer-motion";
import "../css/App.scss";

function Title({ section, text, delayN, viewed }) {
  const enterexit = {
    hidden: {
      left: -50,
      opacity: 0,
    },
    enter: {
      left: 0,
      opacity: 1,
      transition: {
        duration: 0.4,
        delay: delayN * 0.4,
      },
    },
    exit: {
      left: -50,
      opacity: 0,
      transition: {
        duration: 0.3,
      },
    },
  };

  return (
    <motion.div
      custom
      variants={enterexit}
      initial="hidden"
      animate={viewed ? "enter" : "hidden"}
      exit="exit"
      className={`title ${section}`}
    >
      {text}
    </motion.div>
  );
}

export default Title;
