import React from "react"
import { motion } from "framer-motion"
import "../css/App.scss"

function Phone({ source, id, delayN, topY, viewed }) {
  const enterexit = {
    hidden: {
      top: topY + 50,
      opacity: 0,
    },
    enter: {
      top: topY,
      opacity: 1,
      transition: {
        duration: 0.5,
        delay: delayN * 0.2,
      },
    },
    exit: {
      top: topY + 50,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
  }
  return (
    <motion.div
      custom
      variants={enterexit}
      initial="hidden"
      animate={viewed ? "enter" : "hidden"}
      exit="exit"
      className={`image-container ${id}`}
    >
      <div className="phone">
        <img src={source} alt="scoot scoot" className={`image ${id}`} />
      </div>
    </motion.div>
  )
}

export default Phone
