import React from "react"
import { InView } from "react-intersection-observer"
import Header from "./Header"
import Footer from "./Footer"
import Title from "./Title"
import Highlight from "./Highlight"
import Textbox from "./Textbox"
import Image from "./Image"
import MoreProjects from "./MoreProjects"
import "../css/App.scss"
import xfairStyle from "../images/xfair_specs.jpg"
import xfairGradient1 from "../images/xfair_gradient1.jpg"
import xfairGradient2 from "../images/xfair_gradient2.jpg"
import xfairSplash from "../images/xfair_splash.jpg"
import xfairSponsor1 from "../images/xfair_sponsorportal1.jpg"
import xfairSponsor2 from "../images/xfair_sponsorportal2.jpg"
import xfairIcon1 from "../images/xfair_icon1.png"
import xfairIcon2 from "../images/xfair_icon2.png"
import xfairBooklet1 from "../images/xfair_booklet1.png"
import xfairBooklet2 from "../images/xfair_booklet2.png"
import xfairBooklet3 from "../images/xfair_booklet3.png"
import xfairBooklet4 from "../images/xfair_booklet4.png"
import xfairTote from "../images/xfair_tote.png"
import xfairFrame from "../images/xfair_frame.png"

function Xfair({ location }) {
  return (
    <div className="project-page" id="xfair-page">
      <Header />
      <Title section="project" text="xfair" location={location} viewed />

      <div className="content-container overview">
        <div className="sub-container column overview">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="blurb" delayN={0} topY={0} viewed={inView}>
                  xFair is a career fair and tech expo in one that is organized
                  and run by MIT students. As marketing director, I headed
                  branding and design for the 2020 event.
                </Textbox>

                <div className="roles-container">
                  <Highlight section="role" delayN={1} topY={0} viewed={inView}>
                    ui/ux design
                  </Highlight>
                  <Highlight section="role" delayN={2} topY={0} viewed={inView}>
                    branding
                  </Highlight>
                  <Highlight section="role" delayN={2} topY={0} viewed={inView}>
                    graphic design
                  </Highlight>
                </div>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container column overview">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <div className="sub-container column overview">
                  <Highlight
                    section="label"
                    delayN={2}
                    topY={0}
                    viewed={inView}
                  >
                    date
                  </Highlight>
                  <Textbox
                    id="metadata right-align"
                    delayN={1}
                    topY={-20}
                    viewed={inView}
                  >
                    Spring 2020
                  </Textbox>
                </div>
              </div>
            )}
          </InView>
        </div>
      </div>
      <div className="content-container page-content">
        <div className="sub-container buffered full">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight
                  section="project label"
                  delayN={1}
                  topY={0}
                  viewed={inView}
                >
                  splash
                </Highlight>
                <Image
                  source={xfairSplash}
                  id="xfair-web right-align"
                  delayN={2}
                  topY={0}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
        </div>

        <div className="sub-container buffered full">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight
                  section="project label"
                  delayN={1}
                  topY={0}
                  viewed={inView}
                >
                  sponsor portal
                </Highlight>
                <Image
                  source={xfairSponsor1}
                  id="xfair-web right-align"
                  delayN={2}
                  topY={-20}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
        </div>

        <div className="sub-container buffered full">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight
                  section="project label"
                  delayN={1}
                  topY={0}
                  viewed={inView}
                >
                  logged in
                </Highlight>
                <Image
                  source={xfairSponsor2}
                  id="xfair-web right-align"
                  delayN={2}
                  topY={-20}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
        </div>

        <div className="sub-container buffered full">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight
                  section="project label"
                  delayN={1}
                  topY={0}
                  viewed={inView}
                >
                  style
                </Highlight>
                <Image
                  source={xfairStyle}
                  id="xfair-style right-align"
                  delayN={2}
                  topY={-20}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
        </div>

        <div className="sub-container full">
          <div className="sub-container column buffered">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Image
                    source={xfairGradient1}
                    id="xfair-icons"
                    delayN={1}
                    topY={0}
                    viewed={inView}
                  />
                </div>
              )}
            </InView>
          </div>

          <div className="sub-container column buffered">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Image
                    source={xfairGradient2}
                    id="xfair-icons"
                    delayN={2}
                    topY={0}
                    viewed={inView}
                  />
                </div>
              )}
            </InView>
          </div>
        </div>

        <div className="sub-container buffered">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight
                  section="project label"
                  delayN={1}
                  topY={0}
                  viewed={inView}
                >
                  logo design
                </Highlight>
                <Image
                  source={xfairIcon1}
                  id="xfair-icons right-align"
                  delayN={2}
                  topY={-20}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
        </div>

        <div className="sub-container buffered">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight
                  section="project label"
                  delayN={1}
                  topY={0}
                  viewed={inView}
                >
                  another logo design
                </Highlight>
                <Image
                  source={xfairIcon2}
                  id="xfair-icons right-align"
                  delayN={2}
                  topY={-20}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
        </div>

        <div className="sub-container buffered full">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight
                  section="project label"
                  delayN={1}
                  topY={0}
                  viewed={inView}
                >
                  booklet: cover
                </Highlight>
                <Image
                  source={xfairBooklet1}
                  id="xfair-booklet right-align"
                  delayN={2}
                  topY={-20}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
        </div>

        <div className="sub-container buffered full">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight
                  section="project label"
                  delayN={1}
                  topY={0}
                  viewed={inView}
                >
                  booklet: table of contents
                </Highlight>
                <Image
                  source={xfairBooklet2}
                  id="xfair-booklet right-align"
                  delayN={2}
                  topY={-20}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
        </div>

        <div className="sub-container full">
          <div className="sub-container column buffered">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Highlight
                    section="project label"
                    delayN={1}
                    topY={0}
                    viewed={inView}
                  >
                    booklet: inside
                  </Highlight>
                  <Image
                    source={xfairBooklet3}
                    id="xfair-booklet right-align"
                    delayN={2}
                    topY={-20}
                    viewed={inView}
                  />
                </div>
              )}
            </InView>
          </div>

          <div className="sub-container column buffered">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Image
                    source={xfairBooklet4}
                    id="xfair-booklet"
                    delayN={1}
                    topY={0}
                    viewed={inView}
                  />
                </div>
              )}
            </InView>
          </div>
        </div>

        <div className="sub-container full">
          <div className="sub-container column buffered">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Highlight
                    section="project label"
                    delayN={1}
                    topY={0}
                    viewed={inView}
                  >
                    tote
                  </Highlight>
                  <Image
                    source={xfairTote}
                    id="xfair-tote right-align"
                    delayN={2}
                    topY={-20}
                    viewed={inView}
                  />
                </div>
              )}
            </InView>
          </div>

          <div className="sub-container column buffered">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Highlight
                    section="project label"
                    delayN={1}
                    topY={0}
                    viewed={inView}
                  >
                    profile frame
                  </Highlight>
                  <Image
                    source={xfairFrame}
                    id="xfair-frame right-align"
                    delayN={1}
                    topY={0}
                    viewed={inView}
                  />
                </div>
              )}
            </InView>
          </div>
        </div>
      </div>
      <MoreProjects hide="xfair" />
      <Footer />
    </div>
  )
}

export default Xfair
