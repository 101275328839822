import React from "react"
import { InView } from "react-intersection-observer"
import Header from "./Header"
import Footer from "./Footer"
import Title from "./Title"
import Highlight from "./Highlight"
import Textbox from "./Textbox"
import Image from "./Image"
import MoreProjects from "./MoreProjects"
import "../css/App.scss"
import teachbotSetup from "../images/teachbot_setup.jpg"
import teachbotProcess from "../images/teachbot_process.png"
import teachbotBrainstorm from "../images/teachbot_ideation.png"
import teachbotPosition1 from "../images/teachbot_position1.png"
import teachbotPosition2 from "../images/teachbot_position2.png"
import teachbotIcons from "../images/teachbot_pos_orien.gif"
import teachbotEssentials from "../images/teachbot_essentials.png"
import teachbotEncoderBefore from "../images/teachbot_encoder_before.png"
import teachbotEncoder from "../images/teachbot_encoder.gif"

function Teachbot({ location }) {
  return (
    <div className="project-page" id="teachbot-page">
      <Header />
      <Title section="project" text="teachbot" location={location} viewed />

      <div className="content-container overview">
        <div className="sub-container column overview">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="blurb" delayN={0} topY={0} viewed={inView}>
                  TeachBot is a robotic educational tool and curriculum that
                  teaches manufacturing workers about various aspects of
                  robotics using a hands-on, robust learning interface and
                  direct interaction with a robot. I worked in MIT&apos;s
                  d&apos;Arbeloff Laboratory directed by Professor Haruhiko
                  Harry Asada as an undergraduate research assistant focused on
                  design and front-end development for TeachBot learning
                  modules.
                </Textbox>

                <div className="roles-container">
                  <Highlight section="role" delayN={1} topY={0} viewed={inView}>
                    ui/ux design
                  </Highlight>
                  <Highlight section="role" delayN={2} topY={0} viewed={inView}>
                    front-end development
                  </Highlight>
                </div>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container column overview">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <div className="sub-container column overview">
                  <Highlight
                    section="label"
                    delayN={2}
                    topY={0}
                    viewed={inView}
                  >
                    supervisor
                  </Highlight>
                  <Textbox
                    id="metadata right-align"
                    delayN={1}
                    topY={-20}
                    viewed={inView}
                  >
                    Nicholas Selby (MIT Ph.D Candidate)
                  </Textbox>
                </div>
              </div>
            )}
          </InView>
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <div className="sub-container column overview">
                  <Textbox id="metadata" delayN={3} topY={0} viewed={inView}>
                    Summer 2019
                  </Textbox>
                  <Highlight
                    section="label right-align"
                    delayN={4}
                    topY={-20}
                    viewed={inView}
                  >
                    date
                  </Highlight>
                </div>
              </div>
            )}
          </InView>
        </div>
      </div>
      <div className="content-container page-content">
        <div className="sub-container section">
          <div className="sub-container buffered full">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Highlight
                    section="project label"
                    delayN={1}
                    topY={0}
                    viewed={inView}
                  >
                    what is teachbot?
                  </Highlight>
                  <Image
                    source={teachbotSetup}
                    id="teachbot-setup right-align"
                    delayN={2}
                    topY={-10}
                    viewed={inView}
                  />
                </div>
              )}
            </InView>
          </div>

          <div className="sub-container buffered full">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Textbox id="big" delayN={1} topY={-20} viewed={inView}>
                    The number of manufacturing jobs is quickly increasing, but
                    the skilled work force able to fulfill them is not growing
                    quickly enough. TeachBot aims to solve this problem through
                    a hands-on curriculum, in which learners interact directly
                    with a robot arm as they follow along learning modules that
                    are projected onto a surface. Currently, a working prototype
                    of the system is being deployed and tested with industry
                    partners.
                    <br /> <br />I worked with the awesome Teachbot crew to
                    design assets for the learning modules and implement them.
                  </Textbox>
                </div>
              )}
            </InView>
          </div>
        </div>
        <div className="sub-container section">
          <div className="sub-container buffered full">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Highlight
                    section="project label"
                    delayN={1}
                    topY={0}
                    viewed={inView}
                  >
                    the process
                  </Highlight>
                  <Image
                    source={teachbotProcess}
                    id="teachbot-process right-align"
                    delayN={2}
                    topY={-10}
                    viewed={inView}
                  />
                </div>
              )}
            </InView>
          </div>

          <div className="sub-container buffered full">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Textbox id="big" delayN={1} topY={0} viewed={inView}>
                    In the &quot;collaborative brainstorming&quot; stage, my
                    supervisor Nick would bring up a design task. Then,
                    we&apos;d brainstorm some ideas together to get the process
                    going and also make sure we&apos;re on the same page.
                  </Textbox>
                </div>
              )}
            </InView>
          </div>
        </div>

        <div className="sub-container section full">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight
                  section="project"
                  delayN={1}
                  topY={0}
                  viewed={inView}
                >
                  icon design
                </Highlight>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container section">
          <div className="sub-container buffered">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Highlight
                    section="project label"
                    delayN={2}
                    topY={0}
                    viewed={inView}
                  >
                    the problem
                  </Highlight>
                  <Textbox
                    id="big right-align"
                    delayN={1}
                    topY={-20}
                    viewed={inView}
                  >
                    One of my tasks was to make icons that would help people
                    learn the difference between &quot;position&quot; and
                    &quot;orientation&quot;.
                    <br />
                    <br />
                    In one of the Teachbot lessons, the learner must press a
                    button to switch between being able to adjust the robot
                    arm&apos;s position and its orientation. However, from user
                    testing, it seemed that many people had trouble
                    distinguishing between the two concepts and knowing which
                    setting they had switched to.
                    <br />
                    <br />
                    To begin, Nick and I brainstormed designs to indicate which
                    setting - position or orientation - is active.
                  </Textbox>
                </div>
              )}
            </InView>
          </div>

          <div className="sub-container buffered full">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Highlight
                    section="project label"
                    delayN={1}
                    topY={0}
                    viewed={inView}
                  >
                    brainstorm
                  </Highlight>
                  <Image
                    source={teachbotBrainstorm}
                    id="teachbot-brainstorm right-align"
                    delayN={2}
                    topY={0}
                    viewed={inView}
                  />
                </div>
              )}
            </InView>
          </div>

          <div className="sub-container buffered full">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Textbox id="medium" delayN={1} topY={-20} viewed={inView}>
                    We decided on the last design, since colorizing the active
                    icon would highlight it most effectively while keeping both
                    icons visible.
                  </Textbox>
                </div>
              )}
            </InView>
          </div>
        </div>

        <div className="sub-container section">
          <div className="sub-container buffered">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Highlight
                    section="project label sublabel"
                    delayN={1}
                    topY={0}
                    viewed={inView}
                  >
                    initial icon
                  </Highlight>
                  <Image
                    source={teachbotPosition1}
                    id="teachbot-position right-align"
                    delayN={2}
                    topY={-10}
                    viewed={inView}
                  />
                </div>
              )}
            </InView>
          </div>

          <div className="sub-container buffered">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Highlight
                    section="project label sublabel"
                    delayN={2}
                    topY={0}
                    viewed={inView}
                  >
                    final icon
                  </Highlight>
                  <Image
                    source={teachbotPosition2}
                    id="teachbot-position right-align"
                    delayN={3}
                    topY={-10}
                    viewed={inView}
                  />
                </div>
              )}
            </InView>
          </div>

          <div className="sub-container buffered full">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Textbox id="medium" delayN={1} topY={-20} viewed={inView}>
                    I added more dimension to my original design and changed the
                    angle of view in order to better communicate the movement of
                    the robot arm under the selected setting.
                  </Textbox>
                </div>
              )}
            </InView>
          </div>
        </div>
        <div className="sub-container section">
          <div className="sub-container buffered">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Highlight
                    section="project label"
                    delayN={2}
                    topY={0}
                    viewed={inView}
                  >
                    the final product
                  </Highlight>
                  <Image
                    source={teachbotIcons}
                    id="teachbot-icons right-align"
                    delayN={3}
                    topY={-10}
                    viewed={inView}
                  />
                </div>
              )}
            </InView>
          </div>

          <div className="sub-container buffered full">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Textbox id="medium" delayN={1} topY={0} viewed={inView}>
                    Before these icons were made, many learners would forget
                    what mode they&apos;re in when moving the robot arm and
                    would sometimes try to force it to move in the wrong way.
                    <br /> <br />
                    These icons went on to help relieve a lot of frustration
                    from learners!
                  </Textbox>
                </div>
              )}
            </InView>
          </div>
        </div>
        <div className="sub-container section">
          <div className="sub-container full">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Highlight
                    section="project"
                    delayN={1}
                    topY={0}
                    viewed={inView}
                  >
                    educational animation
                  </Highlight>
                </div>
              )}
            </InView>
          </div>

          <div className="sub-container buffered full">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Textbox id="medium" delayN={1} topY={0} viewed={inView}>
                    For another module, I made an animation that visualizes the
                    function of an encoder from a general scope.
                  </Textbox>
                </div>
              )}
            </InView>
          </div>
        </div>

        <div className="sub-container section">
          <div className="sub-container buffered">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Highlight
                    section="project label"
                    delayN={2}
                    topY={0}
                    viewed={inView}
                  >
                    what matters?
                  </Highlight>
                  <Textbox
                    id="medium right-align"
                    delayN={1}
                    topY={-20}
                    viewed={inView}
                  >
                    Because Teachbot is catered toward learners with minimal
                    technical knowledge, it was very important for this
                    animation to focus on the specific lesson being taught and
                    not introduce more complexity than needed that would confuse
                    the learners. Therefore, I started by distinguishing between
                    the essential and nonessential information when describing
                    an encoder in this context.
                  </Textbox>
                </div>
              )}
            </InView>
          </div>

          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref} className="sub-container buffered full">
                <Image
                  source={teachbotEssentials}
                  id="teachbot-essentials"
                  delayN={2}
                  topY={-20}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
        </div>

        <div className="sub-container section">
          <div className="sub-container buffered">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Highlight
                    section="project label"
                    delayN={2}
                    topY={0}
                    viewed={inView}
                  >
                    v.0: rough draft
                  </Highlight>
                  <Image
                    source={teachbotEncoderBefore}
                    id="teachbot-encoder-before right-align"
                    delayN={3}
                    topY={-10}
                    viewed={inView}
                  />
                </div>
              )}
            </InView>
          </div>

          <div className="sub-container buffered full">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Textbox id="medium" delayN={1} topY={0} viewed={inView}>
                    For the final product, I changed the number to a bar in
                    order to abstract away more nonessential information. The
                    focus was shifted to highlighting that the fullness of the
                    green angle corresponds to the fullness up of the green bar.
                    <br />
                    <br />
                    More detail was added to better contextualize the graphic to
                    what the learners see.
                  </Textbox>
                </div>
              )}
            </InView>
          </div>
        </div>

        <div className="sub-container section">
          <div className="sub-container buffered">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Highlight
                    section="project label"
                    delayN={2}
                    topY={0}
                    viewed={inView}
                  >
                    the final product
                  </Highlight>
                  <Image
                    source={teachbotEncoder}
                    id="teachbot-encoder right-align"
                    delayN={3}
                    topY={-10}
                    viewed={inView}
                  />
                </div>
              )}
            </InView>
          </div>
        </div>
      </div>
      <MoreProjects hide="teachbot" />
      <Footer />
    </div>
  )
}

export default Teachbot
