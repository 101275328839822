import React from "react"
import { InView } from "react-intersection-observer"
import Header from "./Header"
import Footer from "./Footer"
import Title from "./Title"
import Highlight from "./Highlight"
import Textbox from "./Textbox"
import Phone from "./Phone"
import MoreProjects from "./MoreProjects"
import "../css/App.scss"
import barsV0 from "../images/bars_v0.png"
import barsV1A from "../images/bars_v1a.png"
import barsV1B from "../images/bars_v1b.png"
import barsV2A from "../images/bars_v2a.png"
import barsV2B from "../images/bars_v2b.png"

function Bars({ location }) {
  return (
    <div className="project-page" id="bars-page">
      <Header />
      <Title
        section="project"
        text="bars of weather"
        location={location}
        viewed
      />

      <div className="content-container overview">
        <div className="sub-container column overview">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="blurb" delayN={0} topY={0} viewed={inView}>
                  Bars of Weather is a simple, yet thorough weather mobile app.
                  It was a brief exercise for course 4.032: Design Studio in
                  Information and Visualization with iterations rolled out every
                  couple days.
                </Textbox>

                <div className="roles-container">
                  <Highlight section="role" delayN={1} topY={0} viewed={inView}>
                    ui/ux design
                  </Highlight>
                  <Highlight section="role" delayN={2} topY={0} viewed={inView}>
                    data visualization
                  </Highlight>
                  <Highlight section="role" delayN={3} topY={0} viewed={inView}>
                    mobile app design
                  </Highlight>
                </div>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container column overview">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <div className="sub-container column overview">
                  <Highlight
                    section="label"
                    delayN={2}
                    topY={0}
                    viewed={inView}
                  >
                    date
                  </Highlight>
                  <Textbox
                    id="metadata right-align"
                    delayN={1}
                    topY={-20}
                    viewed={inView}
                  >
                    Spring 2020
                  </Textbox>
                </div>
              </div>
            )}
          </InView>
        </div>
      </div>
      <div className="content-container page-content">
        <div className="sub-container section">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight
                  section="project"
                  delayN={2}
                  topY={0}
                  viewed={inView}
                >
                  the glow-up
                </Highlight>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container section">
          <div className="sub-container buffered full">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Highlight
                    section="project label"
                    delayN={1}
                    topY={0}
                    viewed={inView}
                  >
                    v.0: lots of symbols
                  </Highlight>
                  <Phone
                    source={barsV0}
                    id="bars-view right-align"
                    delayN={2}
                    topY={-10}
                    viewed={inView}
                  />
                </div>
              )}
            </InView>
          </div>

          <div className="sub-container buffered full">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Textbox id="medium" delayN={3} topY={-20} viewed={inView}>
                    The problem with this first iteration was that there were
                    too many different symbols meaning different things. It
                    required users to learn what each symbol meant in order to
                    understand what was being shown. The UI had to become more
                    cohesive and easy to learn.
                  </Textbox>
                </div>
              )}
            </InView>
          </div>
        </div>

        <div className="sub-container section">
          <div className="sub-container buffered">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Highlight
                    section="project label"
                    delayN={1}
                    topY={0}
                    viewed={inView}
                  >
                    v.1: lots of bars
                  </Highlight>
                  <Phone
                    source={barsV1A}
                    id="bars-view right-align"
                    delayN={2}
                    topY={-10}
                    viewed={inView}
                  />
                </div>
              )}
            </InView>
          </div>

          <div className="sub-container buffered">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Highlight
                    section="project label"
                    delayN={1}
                    topY={0}
                    viewed={inView}
                  >
                    specific view
                  </Highlight>
                  <Phone
                    source={barsV1B}
                    id="bars-view right-align"
                    delayN={2}
                    topY={-20}
                    viewed={inView}
                  />
                </div>
              )}
            </InView>
          </div>

          <div className="sub-container buffered full">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Textbox id="medium" delayN={3} topY={-20} viewed={inView}>
                    This iteration had a much more cohesive design that was
                    easier to understand. However, the comprehensive view
                    contained many bright colors that made it feel overloaded
                    and difficult to digest.
                  </Textbox>
                </div>
              )}
            </InView>
          </div>
        </div>

        <div className="sub-container section full">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight
                  section="project"
                  delayN={2}
                  topY={0}
                  viewed={inView}
                >
                  and now...
                  <br /> <br />
                  the final product reveal
                </Highlight>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container section">
          <div className="sub-container buffered">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Phone
                    source={barsV2A}
                    id="bars-view"
                    delayN={2}
                    topY={-10}
                    viewed={inView}
                  />
                </div>
              )}
            </InView>
          </div>

          <div className="sub-container buffered">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Phone
                    source={barsV2B}
                    id="bars-view"
                    delayN={2}
                    topY={-20}
                    viewed={inView}
                  />
                </div>
              )}
            </InView>
          </div>

          <div className="sub-container buffered full">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Textbox id="big" delayN={3} topY={-20} viewed={inView}>
                    To solve the problem of readability, I used different levels
                    of opacity to highlight key information and de-emphasize
                    less important information. In the final version, only the
                    bars that indicate notable weather (like if it&apos;s
                    exceptionally cloudy, or if it&apos;s likely to precipitate)
                    are highlighted. I also switched the left and right graphs
                    to suit a left-to-right reading demographic.
                  </Textbox>
                </div>
              )}
            </InView>
          </div>
        </div>

        <div className="sub-container section">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight
                  section="project label"
                  delayN={2}
                  topY={0}
                  viewed={inView}
                >
                  fun fact
                </Highlight>
                <Textbox
                  id="big right-align"
                  delayN={1}
                  topY={-20}
                  viewed={inView}
                >
                  I named this project after{" "}
                  <a
                    href="https://listart.mit.edu/public-art-map/bars-color-within-squares-mit"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    this art piece
                  </a>
                  . The colors might look familiar too :)
                </Textbox>
              </div>
            )}
          </InView>
        </div>
      </div>
      <MoreProjects hide="bars" />
      <Footer />
    </div>
  )
}

export default Bars
