import React from "react";
import { InView } from "react-intersection-observer";
import Header from "./Header";
import Footer from "./Footer";
import Title from "./Title";
import Highlight from "./Highlight";
import Textbox from "./Textbox";
import Image from "./Image";
import MoreProjects from "./MoreProjects";
import "../css/App.scss";
import languageV0 from "../images/language_v0.png";
import languageV1 from "../images/language_v1.png";
import languageV3 from "../images/language_v3.png";
import languageV4 from "../images/language_v4.png";
import languageViewSwitch from "../images/language_view_switch.gif";
import languageViewSelect from "../images/language_view_select.gif";
import languageObs1 from "../images/language_obs1.png";

function Project({ location }) {
  return (
    <div className="project-page" id="language-page">
      <Header />
      <Title
        section="project"
        text="language of color"
        location={location}
        viewed
      />

      <div className="content-container overview">
        <div className="sub-container column overview">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="blurb" delayN={0} topY={0} viewed={inView}>
                  An exploration of the correlations between what languages
                  people speak and how they classify colors.
                </Textbox>

                <div className="roles-container">
                  <Highlight section="role" delayN={1} topY={0} viewed={inView}>
                    data visualization
                  </Highlight>
                  <Highlight section="role" delayN={1} topY={0} viewed={inView}>
                    experience design
                  </Highlight>
                </div>

                <Highlight
                  section="label overview-link"
                  delayN={4}
                  topY={-100}
                  viewed={inView}
                >
                  <a
                    href={`${process.env.PUBLIC_URL}/colornames/index.html`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <u>check it out</u>
                  </a>
                </Highlight>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container column overview">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <div className="sub-container column overview">
                  <Highlight
                    section="label"
                    delayN={2}
                    topY={0}
                    viewed={inView}
                  >
                    date
                  </Highlight>
                  <Textbox
                    id="metadata right-align"
                    delayN={3}
                    topY={-20}
                    viewed={inView}
                  >
                    Spring 2020
                  </Textbox>
                </div>
              </div>
            )}
          </InView>
        </div>
      </div>

      <InView triggerOnce>
        {({ inView, ref }) => (
          <div ref={ref}>
            <Title
              section="project-section"
              text="the inspiration"
              delayN={0}
              viewed={inView}
            />
          </div>
        )}
      </InView>

      <div className="sub-container section">
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight section="banner" delayN={0} topY={0} viewed={inView}>
                  many languages, many colors
                </Highlight>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="big" delayN={0} topY={0} viewed={inView}>
                  Through a psychology course I took in college, I learned that
                  there's an interesting body of research that has been done
                  involving <b>color perception across cultures</b>.
                  <br />
                  <br />
                  When I dove deeper, I came across the{" "}
                  <a
                    href="https://uwdata.github.io/color-naming-in-different-languages/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Many Languages, Many Colors
                  </a>{" "}
                  project. The project has made public a rich dataset sourced
                  from subjects across the globe. For my{" "}
                  <b>language of color</b> project, I downloaded this dataset
                  and played around with it to create something interesting.
                </Textbox>
              </div>
            )}
          </InView>
        </div>
      </div>

      <InView triggerOnce>
        {({ inView, ref }) => (
          <div ref={ref}>
            <Title
              section="project-section"
              text="the evolution"
              delayN={0}
              viewed={inView}
            />
          </div>
        )}
      </InView>

      <div className="sub-container section">
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight section="banner" delayN={0} topY={0} viewed={inView}>
                  reverse design
                </Highlight>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="big" delayN={0} topY={0} viewed={inView}>
                  This project followed a <b>reverse design process</b>.
                  <br />
                  <br />
                  Rather than finding a <b>solution to a problem</b>, I was
                  finding an <b>opportunity within a dataset</b>.
                  <br />
                  <br />
                  Through incremental iterations, I looked for opportunities
                  within the data and shaped the visualization around the most
                  interesting insights.
                </Textbox>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight section="banner" delayN={0} topY={0} viewed={inView}>
                  v1: starting from scratch
                </Highlight>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="big" delayN={0} topY={0} viewed={inView}>
                  To start, I simply displayed the data as text next to the
                  corresponding swatches on the color spectrum.
                  <br />
                  <br />
                  Looking keenly at this rough visualization across all
                  available languages, I started to form an idea for what to do
                  next.
                </Textbox>
              </div>
            )}
          </InView>
        </div>
        <InView triggerOnce>
          {({ inView, ref }) => (
            <div ref={ref} className="sub-container buffered-v spaced">
              <Image
                source={languageV0}
                id="language-versions"
                delayN={0}
                topY={0}
                viewed={inView}
              />
            </div>
          )}
        </InView>
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight section="banner" delayN={0} topY={0} viewed={inView}>
                  v2: the "perceived" spectrum
                </Highlight>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="big" delayN={0} topY={0} viewed={inView}>
                  I wondered what the <b>perceived</b> color spectrum would look
                  like for each language. This was the focus of version 2.
                  <br />
                  <br />I also started to notice rough patterns in the level of{" "}
                  <b>agreement in color naming</b> across the spectrum that I
                  wanted to surface further.
                </Textbox>
              </div>
            )}
          </InView>
        </div>
        <InView triggerOnce>
          {({ inView, ref }) => (
            <div ref={ref} className="sub-container buffered-v spaced">
              <Image
                source={languageV1}
                id="language-versions"
                delayN={0}
                topY={0}
                viewed={inView}
              />
            </div>
          )}
        </InView>
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight section="banner" delayN={0} topY={0} viewed={inView}>
                  v3: across languages
                </Highlight>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="big" delayN={0} topY={0} viewed={inView}>
                  The perceived spectrum from version 2 surfaced very clear
                  similarities and differences in color naming across languages.
                  For version 3, I focused on making these patterns more
                  visible.
                  <br />
                  <br />
                  The granular data for each color swatch is useful in seeing
                  how a word, for example "cyan", starts to gain prominence
                  around a particular area of the spectrum. I reformatted this
                  part of the visualization to be more easily digestible.
                </Textbox>
              </div>
            )}
          </InView>
        </div>
        <InView triggerOnce>
          {({ inView, ref }) => (
            <div ref={ref} className="sub-container buffered-v spaced">
              <Image
                source={languageV3}
                id="language-versions"
                delayN={0}
                topY={0}
                viewed={inView}
              />
            </div>
          )}
        </InView>
      </div>

      <InView triggerOnce>
        {({ inView, ref }) => (
          <div ref={ref}>
            <Title
              section="project-section"
              text="the product"
              delayN={0}
              viewed={inView}
            />
          </div>
        )}
      </InView>

      <div className="sub-container section">
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight section="banner" delayN={0} topY={0} viewed={inView}>
                  concept
                </Highlight>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="big" delayN={0} topY={0} viewed={inView}>
                  From version 3, I found that the most interesting part of the
                  data was seeing similarities and differences in the perceived
                  color spectrum across languages. <b>Back-to-back graphs</b>{" "}
                  are a highly effective method to observe this aspect of the
                  data. They served as the central part of the visualization.
                  <br />
                  <br />
                  The user experience was refined from version 3, to make
                  interacting with and understanding the visualization more
                  intuitive.
                </Textbox>
              </div>
            )}
          </InView>
        </div>
        <InView triggerOnce>
          {({ inView, ref }) => (
            <div ref={ref} className="sub-container buffered-v spaced">
              <Image
                source={languageV4}
                id="language-final-version"
                delayN={0}
                topY={0}
                viewed={inView}
              />
            </div>
          )}
        </InView>
        <InView triggerOnce>
          {({ inView, ref }) => (
            <div ref={ref} className="sub-container buffered-v spaced">
              <Image
                source={languageViewSwitch}
                id="language-versions"
                delayN={0}
                topY={0}
                viewed={inView}
              />
            </div>
          )}
        </InView>
        <InView triggerOnce>
          {({ inView, ref }) => (
            <div ref={ref} className="sub-container buffered-v spaced">
              <Image
                source={languageViewSelect}
                id="language-versions"
                delayN={0}
                topY={0}
                viewed={inView}
              />
            </div>
          )}
        </InView>
      </div>
      <div className="sub-container section">
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight section="banner" delayN={0} topY={0} viewed={inView}>
                  insights
                </Highlight>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="big" delayN={0} topY={0} viewed={inView}>
                  Language of Color provides a way to easily detect and
                  visualize interesting patterns of color classification across
                  languages.
                  <br />
                  <br />
                  For example, who knew that Russian and Korean color
                  classification was so similar?
                  <br />
                  <br />
                  <a
                    href={`${process.env.PUBLIC_URL}/colornames/index.html`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Interact with Language of Color!
                  </a>{" "}
                  It is full of rich and colorful insights.
                </Textbox>
              </div>
            )}
          </InView>
        </div>
        <InView triggerOnce>
          {({ inView, ref }) => (
            <div ref={ref} className="sub-container buffered-v spaced">
              <Image
                source={languageObs1}
                id="language-versions"
                delayN={1}
                topY={0}
                viewed={inView}
              />
            </div>
          )}
        </InView>
      </div>

      <MoreProjects hide="language" />
      <Footer />
    </div>
  );
}

export default Project;
