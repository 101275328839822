import React from "react";
import { Link } from "react-router-dom";
import { InView } from "react-intersection-observer";
import Title from "./Title";
import Image from "./Image";
import "../css/App.scss";
import koodoscasethumbnailIMG from "../images/koodoscase_thumbnail.png";
import ghostthumbnailIMG from "../images/ghost_thumbnail.png";
import languagethumbnailIMG from "../images/language_thumbnail.png";
import guitarthumbnailIMG from "../images/guitar_thumbnail.png";
import koodosartthumbnailIMG from "../images/koodosart_thumbnail.png";

function MoreProjects({ location, hide }) {
  return (
    <div className="more-projects">
      <InView triggerOnce>
        {({ inView, ref }) => (
          <div ref={ref}>
            <Title
              section="project"
              text="more projects"
              location={location}
              viewed={inView}
            />
          </div>
        )}
      </InView>

      <div className="more-projects-container">
        <div className="more-projects-padding" />
        {hide === "koodoscase" ? null : (
          <div className="sub-container projects-more spaced">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Link to="/projects/koodos-case">
                    <Image
                      source={koodoscasethumbnailIMG}
                      id="more-projects-thumbnail"
                      topY={0}
                      delayN={0}
                      viewed={inView}
                    />
                  </Link>
                </div>
              )}
            </InView>
          </div>
        )}

        {hide === "ghost" ? null : (
          <div className="sub-container projects-more spaced">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Link to="/projects/ghost">
                    <Image
                      source={ghostthumbnailIMG}
                      id="more-projects-thumbnail"
                      topY={0}
                      delayN={0}
                      viewed={inView}
                    />
                  </Link>
                </div>
              )}
            </InView>
          </div>
        )}

        {hide === "language" ? null : (
          <div className="sub-container projects-more spaced">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Link to="/projects/language-of-color">
                    <Image
                      source={languagethumbnailIMG}
                      id="more-projects-thumbnail"
                      topY={0}
                      delayN={0}
                      viewed={inView}
                    />
                  </Link>
                </div>
              )}
            </InView>
          </div>
        )}

        {hide === "guitar" ? null : (
          <div className="sub-container projects-more">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Link to="/projects/guitar">
                    <Image
                      source={guitarthumbnailIMG}
                      id="more-projects-thumbnail"
                      topY={0}
                      delayN={0}
                      viewed={inView}
                    />
                  </Link>
                </div>
              )}
            </InView>
          </div>
        )}

        {hide === "koodosart" ? null : (
          <div className="sub-container projects-more">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Link to="/projects/koodos-art">
                    <Image
                      source={koodosartthumbnailIMG}
                      id="more-projects-thumbnail"
                      topY={0}
                      delayN={0}
                      viewed={inView}
                    />
                  </Link>
                </div>
              )}
            </InView>
          </div>
        )}

        <div className="more-projects-padding" />
      </div>
    </div>
  );
}

export default MoreProjects;
