import React from "react"
import { InView } from "react-intersection-observer"
import Header from "./Header"
import Footer from "./Footer"
import Title from "./Title"
import Highlight from "./Highlight"
import Textbox from "./Textbox"
import Image from "./Image"
import MoreProjects from "./MoreProjects"
import "../css/App.scss"
import techxStyle from "../images/techx_styleguide.jpg"
import techxOld from "../images/techx_app0.png"
import techxV0 from "../images/techx_app1.png"
import techxFinal from "../images/techx_app2.png"

function TechX({ location }) {
  return (
    <div className="project-page" id="techx-page">
      <Header />
      <Title section="project" text="techx apply" location={location} viewed />

      <div className="content-container overview">
        <div className="sub-container column overview">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="blurb" delayN={0} topY={0} viewed={inView}>
                  TechX is an MIT student-run organization which aims to empower
                  high school and college students through various opportunities
                  to participate in tech innovation. The application portal,
                  which was revamped to meet the fall 2019 application cycle, is
                  more streamlined and embodies the TechX brand. I worked with
                  two of my web dev friends who implemented the webpages.
                </Textbox>

                <div className="roles-container">
                  <Highlight section="role" delayN={1} topY={0} viewed={inView}>
                    ui/ux design
                  </Highlight>
                  <Highlight section="role" delayN={2} topY={0} viewed={inView}>
                    branding
                  </Highlight>
                </div>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container column overview">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <div className="sub-container column overview">
                  <Highlight
                    section="label"
                    delayN={2}
                    topY={0}
                    viewed={inView}
                  >
                    collaborators
                  </Highlight>
                  <Textbox
                    id="metadata right-align"
                    delayN={1}
                    topY={-20}
                    viewed={inView}
                  >
                    Bryan Chen (MIT &apos;21)
                    <br />
                    Kevin Fang (MIT &apos;21)
                  </Textbox>
                </div>
              </div>
            )}
          </InView>
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <div className="sub-container column overview">
                  <Textbox id="metadata" delayN={3} topY={0} viewed={inView}>
                    Summer 2019
                  </Textbox>
                  <Highlight
                    section="label right-align"
                    delayN={4}
                    topY={-20}
                    viewed={inView}
                  >
                    date
                  </Highlight>
                </div>
              </div>
            )}
          </InView>
        </div>
      </div>
      <div className="content-container page-content">
        <div className="sub-container buffered full">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight
                  section="project label"
                  delayN={1}
                  topY={0}
                  viewed={inView}
                >
                  style guide
                </Highlight>
                <Image
                  source={techxStyle}
                  id="techx-style right-align"
                  delayN={2}
                  topY={-10}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
        </div>

        <div className="sub-container buffered full">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight
                  section="project label"
                  delayN={1}
                  topY={0}
                  viewed={inView}
                >
                  old design: an ugly google form
                </Highlight>
                <Image
                  source={techxOld}
                  id="techx-screen right-align"
                  delayN={2}
                  topY={-10}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
        </div>

        <div className="sub-container buffered full">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight
                  section="project label"
                  delayN={1}
                  topY={0}
                  viewed={inView}
                >
                  redesign v.0
                </Highlight>
                <Image
                  source={techxV0}
                  id="techx-screen right-align"
                  delayN={2}
                  topY={-10}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
        </div>

        <div className="sub-container buffered full">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight
                  section="project label"
                  delayN={1}
                  topY={0}
                  viewed={inView}
                >
                  final design
                </Highlight>
                <Image
                  source={techxFinal}
                  id="techx-screen right-align"
                  delayN={2}
                  topY={-10}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
        </div>
      </div>
      <MoreProjects hide="techx" />
      <Footer />
    </div>
  )
}

export default TechX
