import React from "react"
import { Link } from "react-router-dom"
import { motion } from "framer-motion"
import "../css/App.scss"

function Header() {
  const enterexit = {
    hidden: {
      top: 20,
      opacity: 0,
    },
    enter: {
      top: 0,
      opacity: 1,
      transition: {
        duration: 0.4,
        delay: 0.2,
      },
    },
    exit: {
      top: 20,
      opacity: 0,
      transition: {
        duration: 0.3,
      },
    },
  }

  return (
    <div className="header">
      <motion.div
        variants={enterexit}
        initial="hidden"
        animate="enter"
        exit="exit"
        className="logo-container"
      >
        <Link to="/" className="logo">
          r
        </Link>
      </motion.div>
    </div>
  )
}

export default Header
