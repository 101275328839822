import React from "react";
import { Link } from "react-router-dom";
import { InView } from "react-intersection-observer";
import Header from "./Header";
import Footer from "./Footer";
import Title from "./Title";
import Highlight from "./Highlight";
import Textbox from "./Textbox";
import Image from "./Image";
import meIMG from "../images/intro.JPG";
import koodosCaseThumbnail from "../images/koodoscase_thumbnail.png";
import guitarThumbnail from "../images/guitar_thumbnail.png";
import languageThumbnail from "../images/language_thumbnail.png";
import ghostThumbnail from "../images/ghost_thumbnail.png";
import koodosArtThumbnail from "../images/koodosart_thumbnail.png";

import "../css/App.scss";

function Home() {
  return (
    <div className="page">
      <Header />

      <InView triggerOnce>
        {({ inView, ref }) => (
          <div ref={ref}>
            <Title section="intro" text="hello" delayN={0} viewed={inView} />
          </div>
        )}
      </InView>

      <div className="content-container" id="intro-page">
        <div className="sub-container">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight section="intro" topY={0} delayN={2} viewed={inView}>
                  hi, <br />
                  i&apos;m rae.
                  <br /> <br />
                  i like to
                  <br />
                  design.
                </Highlight>
                <Image
                  source={meIMG}
                  id="intro"
                  topY={-80}
                  delayN={1}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
        </div>

        <div className="sub-container">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="intro" topY={0} delayN={3} viewed={inView}>
                  <div className="textbox-text">
                    I&apos;m a designer dedicated to creating{" "}
                    <b>fun and uplifting</b> digital experiences. I have an
                    undying love of learning, with interests in psychology,
                    philosophy, computer science, video games, internet culture,
                    mental health, and cats.
                    <br /> <br />
                    Currently, I am working as a <b>freelancer</b> for early stage startups and products.
                  </div>
                </Textbox>
              </div>
            )}
          </InView>
        </div>
      </div>

      <InView triggerOnce>
        {({ inView, ref }) => (
          <div ref={ref}>
            <Title
              section="projects"
              text="projects"
              delayN={1}
              viewed={inView}
            />
          </div>
        )}
      </InView>

      <Link to="/projects/koodos-case">
        <div className="content-container main-project">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox
                  id="project-title-main"
                  delayN={0}
                  topY={0}
                  viewed={inView}
                >
                  koodos: case study
                </Textbox>
                <Textbox
                  id="project-description"
                  delayN={0}
                  topY={0}
                  viewed={inView}
                >
                  A system to facilitate positive interactions between users in
                  an online space. A safer, more meaningful alternative to likes
                  and comments on social media.
                </Textbox>

                <div className="roles-container">
                  <Highlight section="role" delayN={1} topY={0} viewed={inView}>
                    product design
                  </Highlight>
                  <Highlight section="role" delayN={1} topY={0} viewed={inView}>
                    ui/ux design
                  </Highlight>
                </div>
              </div>
            )}
          </InView>
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Image
                  source={koodosCaseThumbnail}
                  id="main-project"
                  topY={0}
                  delayN={1}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
        </div>
      </Link>

      <Link to="/projects/guitar">
        <div className="content-container main-project">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox
                  id="project-title-main"
                  delayN={0}
                  topY={0}
                  viewed={inView}
                >
                  guitar
                </Textbox>
                <Textbox
                  id="project-description"
                  delayN={0}
                  topY={0}
                  viewed={inView}
                >
                  More than just a font. A typeface that can be experienced in
                  three different ways.
                </Textbox>

                <div className="roles-container">
                  <Highlight section="role" delayN={1} topY={0} viewed={inView}>
                    experience design
                  </Highlight>
                  <Highlight section="role" delayN={2} topY={0} viewed={inView}>
                    type design
                  </Highlight>
                </div>
              </div>
            )}
          </InView>
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Image
                  source={guitarThumbnail}
                  id="main-project"
                  topY={0}
                  delayN={1}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
        </div>
      </Link>

      <Link to="/projects/language-of-color">
        <div className="content-container main-project">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox
                  id="project-title-main"
                  delayN={0}
                  topY={0}
                  viewed={inView}
                >
                  language of color
                </Textbox>
                <Textbox
                  id="project-description"
                  delayN={0}
                  topY={0}
                  viewed={inView}
                >
                  An exploration of the correlations between what languages
                  people speak and how they classify colors.
                </Textbox>

                <div className="roles-container">
                  <Highlight section="role" delayN={1} topY={0} viewed={inView}>
                    data visualization
                  </Highlight>
                  <Highlight section="role" delayN={2} topY={0} viewed={inView}>
                    ui/ux design
                  </Highlight>
                </div>
              </div>
            )}
          </InView>
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Image
                  source={languageThumbnail}
                  id="main-project"
                  topY={0}
                  delayN={1}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
        </div>
      </Link>

      <Link to="/projects/ghost">
        <div className="content-container main-project">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox
                  id="project-title-main"
                  delayN={0}
                  topY={0}
                  viewed={inView}
                >
                  ghost
                </Textbox>
                <Textbox
                  id="project-description"
                  delayN={0}
                  topY={0}
                  viewed={inView}
                >
                  A nostalgic multi-player online word game for friends.
                </Textbox>

                <div className="roles-container">
                  <Highlight section="role" delayN={1} topY={0} viewed={inView}>
                    game design
                  </Highlight>
                  <Highlight section="role" delayN={2} topY={0} viewed={inView}>
                    ui/ux design
                  </Highlight>
                </div>
              </div>
            )}
          </InView>
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Image
                  source={ghostThumbnail}
                  id="main-project"
                  topY={0}
                  delayN={1}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
        </div>
      </Link>

      <Link to="/projects/koodos-art">
        <div className="content-container main-project">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox
                  id="project-title-main"
                  delayN={0}
                  topY={0}
                  viewed={inView}
                >
                  koodos: art
                </Textbox>
                <Textbox
                  id="project-description"
                  delayN={0}
                  topY={0}
                  viewed={inView}
                >
                  A collection of digital illustrations produced for Koodos
                  product.
                </Textbox>

                <div className="roles-container">
                  <Highlight section="role" delayN={1} topY={0} viewed={inView}>
                    graphic design
                  </Highlight>
                  <Highlight section="role" delayN={2} topY={0} viewed={inView}>
                    illustration
                  </Highlight>
                </div>
              </div>
            )}
          </InView>
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Image
                  source={koodosArtThumbnail}
                  id="main-project"
                  topY={0}
                  delayN={1}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
        </div>
      </Link>

      <InView triggerOnce>
        {({ inView, ref }) => (
          <div ref={ref}>
            <Title section="resume" text="resume" delayN={1} viewed={inView} />
          </div>
        )}
      </InView>

      <InView triggerOnce>
        {({ inView, ref }) => (
          <div ref={ref} className="content-container">
            <Highlight section="resume" topY={0} delayN={2} viewed={inView}>
              <a
                href={`${process.env.PUBLIC_URL}/assets/kwak_resume.pdf`}
                target="_blank"
                rel="noopener noreferrer"
              >
                click here
              </a>
              <br />
              to view my resume.
            </Highlight>
          </div>
        )}
      </InView>

      <InView triggerOnce>
        {({ inView, ref }) => (
          <div ref={ref}>
            <Title
              section="contact"
              text="contact"
              delayN={2}
              viewed={inView}
            />
          </div>
        )}
      </InView>

      <div className="content-container">
        <InView triggerOnce>
          {({ inView, ref }) => (
            <div ref={ref}>
              <Textbox id="medium" topY={0} delayN={3} viewed={inView}>
                Want to say hello?
                <br />
                <br />
                Feel free to reach me by{" "}
                <a href="mailto: rae@kwak.design">email</a>!
              </Textbox>
            </div>
          )}
        </InView>
      </div>

      <Footer />
    </div>
  );
}

export default Home;
