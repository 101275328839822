import React from "react"
import { InView } from "react-intersection-observer"
import Header from "./Header"
import Footer from "./Footer"
import Title from "./Title"
import Highlight from "./Highlight"
import Textbox from "./Textbox"
import Image from "./Image"
import MoreProjects from "./MoreProjects"
import "../css/App.scss"
import micWireframes from "../images/mic_wireframe.png"
import micLogoOld from "../images/mic_logo_old.png"
import micLogoNew from "../images/mic_logo_new.png"
import micBlackLogo from "../images/mic_thumbnail.png"
import micStyle from "../images/mic_styleguide.png"
import micScreen from "../images/mic_animation.gif"

function MIC({ location }) {
  return (
    <div className="project-page" id="mic-page">
      <Header />
      <Title section="project" text="mit mic" location={location} viewed />

      <div className="content-container overview">
        <div className="sub-container column overview">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="blurb" delayN={0} topY={0} viewed={inView}>
                  MIC (Machine Intelligence Community) is an intercollege
                  organization focused on promoting and fostering the growing
                  interest around machine intelligence. I was asked by MIT MIC
                  to lead their rebranding effort, which included a redesign for
                  their website.
                </Textbox>

                <div className="roles-container">
                  <Highlight section="role" delayN={1} topY={0} viewed={inView}>
                    ui/ux design
                  </Highlight>
                  <Highlight section="role" delayN={2} topY={0} viewed={inView}>
                    branding
                  </Highlight>
                </div>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container column overview">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <div className="sub-container column overview">
                  <Highlight
                    section="label"
                    delayN={2}
                    topY={0}
                    viewed={inView}
                  >
                    date
                  </Highlight>
                  <Textbox
                    id="metadata right-align"
                    delayN={1}
                    topY={-20}
                    viewed={inView}
                  >
                    Summer 2019
                  </Textbox>
                </div>
              </div>
            )}
          </InView>
        </div>
      </div>
      <div className="content-container page-content">
        <div className="sub-container buffered full">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight
                  section="project label"
                  delayN={1}
                  topY={0}
                  viewed={inView}
                >
                  wireframes
                </Highlight>
                <Image
                  source={micWireframes}
                  id="mic-wireframes right-align"
                  delayN={2}
                  topY={-10}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
        </div>

        <div className="sub-container buffered full">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight
                  section="project"
                  delayN={1}
                  topY={0}
                  viewed={inView}
                >
                  logo tweaks
                </Highlight>
              </div>
            )}
          </InView>
        </div>

        <div className="sub-container buffered">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight
                  section="project label sublabel"
                  delayN={1}
                  topY={0}
                  viewed={inView}
                >
                  old
                </Highlight>
                <Image
                  source={micLogoOld}
                  id="mic-logo right-align"
                  delayN={2}
                  topY={-10}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
        </div>

        <div className="sub-container buffered">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight
                  section="project label sublabel"
                  delayN={1}
                  topY={0}
                  viewed={inView}
                >
                  new
                </Highlight>
                <Image
                  source={micLogoNew}
                  id="mic-logo right-align"
                  delayN={2}
                  topY={-10}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
        </div>

        <div className="sub-container buffered full">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight
                  section="project label sublabel"
                  delayN={1}
                  topY={0}
                  viewed={inView}
                >
                  looks great on black too
                </Highlight>
                <Image
                  source={micBlackLogo}
                  id="mic-black-logo right-align"
                  delayN={2}
                  topY={-10}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
        </div>

        <div className="sub-container buffered full">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight
                  section="project label"
                  delayN={1}
                  topY={0}
                  viewed={inView}
                >
                  style guide
                </Highlight>
                <Image
                  source={micStyle}
                  id="mic-style right-align"
                  delayN={2}
                  topY={-10}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
        </div>

        <div className="sub-container buffered full">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight
                  section="project label"
                  delayN={1}
                  topY={0}
                  viewed={inView}
                >
                  web page
                </Highlight>
                <Image
                  source={micScreen}
                  id="mic-screen right-align"
                  delayN={2}
                  topY={-10}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
        </div>
      </div>
      <MoreProjects hide="mic" />
      <Footer />
    </div>
  )
}

export default MIC
