import React from "react";
import { InView } from "react-intersection-observer";
import Header from "./Header";
import Footer from "./Footer";
import Title from "./Title";
import Highlight from "./Highlight";
import Textbox from "./Textbox";
import Image from "./Image";
import MoreProjects from "./MoreProjects";
import "../css/App.scss";
import treasuresAll from "../images/koodosart_treasures.png";
import treasuresLevels from "../images/koodosart_thumbnail.png";
import koodosPeople from "../images/koodosart_people.png";
import koodosCurrency from "../images/koodosart_currency.png";
import koodosGem from "../images/koodosart_gem.png";

function KoodosArt({ location }) {
  return (
    <div className="project-page" id="koodosart-page">
      <Header />
      <Title section="project" text="koodos: art" location={location} viewed />

      <div className="content-container overview">
        <div className="sub-container column overview">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="blurb" delayN={0} topY={0} viewed={inView}>
                  A collection of digital illustrations produced for Koodos
                  product.
                </Textbox>

                <div className="roles-container">
                  <Highlight section="role" delayN={1} topY={0} viewed={inView}>
                    graphic design
                  </Highlight>
                  <Highlight section="role" delayN={2} topY={0} viewed={inView}>
                    illustration
                  </Highlight>
                </div>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container column overview">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <div className="sub-container column overview">
                  <Highlight
                    section="label"
                    delayN={2}
                    topY={0}
                    viewed={inView}
                  >
                    date
                  </Highlight>
                  <Textbox
                    id="metadata right-align"
                    delayN={1}
                    topY={-20}
                    viewed={inView}
                  >
                    2021-2022
                  </Textbox>
                </div>
              </div>
            )}
          </InView>
        </div>
      </div>

      <InView triggerOnce>
        {({ inView, ref }) => (
          <div ref={ref}>
            <Title
              section="project-section"
              text="the treasure"
              delayN={0}
              viewed={inView}
            />
          </div>
        )}
      </InView>

      <div className="sub-container section">
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="big" delayN={0} topY={0} viewed={inView}>
                  Treasures are <b>souvenirs</b> of special Koodos moments and
                  accomplishments that users can unlock.
                </Textbox>
              </div>
            )}
          </InView>
        </div>
        <InView triggerOnce>
          {({ inView, ref }) => (
            <div
              ref={ref}
              className="sub-container buffered-h buffered-v centered-h"
            >
              <Image
                source={treasuresAll}
                id="koodos-art"
                delayN={0}
                topY={0}
                viewed={inView}
              />
            </div>
          )}
        </InView>
        <InView triggerOnce>
          {({ inView, ref }) => (
            <div
              ref={ref}
              className="sub-container buffered-h buffered-v centered-h"
            >
              <Image
                source={treasuresLevels}
                id="koodos-art"
                delayN={0}
                topY={0}
                viewed={inView}
              />
            </div>
          )}
        </InView>
      </div>

      <InView triggerOnce>
        {({ inView, ref }) => (
          <div ref={ref}>
            <Title
              section="project-section"
              text="the people"
              delayN={0}
              viewed={inView}
            />
          </div>
        )}
      </InView>

      <div className="sub-container section">
        <InView triggerOnce>
          {({ inView, ref }) => (
            <div
              ref={ref}
              className="sub-container buffered-h buffered-v centered-h"
            >
              <Image
                source={koodosPeople}
                id="koodos-art"
                delayN={0}
                topY={0}
                viewed={inView}
              />
            </div>
          )}
        </InView>
      </div>

      <InView triggerOnce>
        {({ inView, ref }) => (
          <div ref={ref}>
            <Title
              section="project-section"
              text="the currency"
              delayN={0}
              viewed={inView}
            />
          </div>
        )}
      </InView>
      
      <div className="sub-container section">
        <InView triggerOnce>
          {({ inView, ref }) => (
            <div
              ref={ref}
              className="sub-container buffered-h buffered-v centered-h"
            >
              <Image
                source={koodosCurrency}
                id="koodos-art"
                delayN={0}
                topY={0}
                viewed={inView}
              />
            </div>
          )}
        </InView>
        <InView triggerOnce>
          {({ inView, ref }) => (
            <div
              ref={ref}
              className="sub-container buffered-h buffered-v centered-h"
            >
              <Image
                source={koodosGem}
                id="koodos-gem"
                delayN={0}
                topY={0}
                viewed={inView}
              />
            </div>
          )}
        </InView>
      </div>

      <MoreProjects hide="koodosart" />
      <Footer />
    </div>
  );
}

export default KoodosArt;
