import React from "react"
import { Link } from "react-router-dom"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import "../css/App.scss"

function Footer() {
  const enterexit = {
    hidden: {
      top: -20,
      opacity: 0,
    },
    enter: {
      top: 0,
      opacity: 1,
      transition: {
        duration: 0.4,
        delay: 0.7,
      },
    },
    exit: {
      top: -20,
      opacity: 0,
      transition: {
        duration: 0.3,
      },
    },
  }

  return (
    <InView triggerOnce>
      {({ inView, ref }) => (
        <div ref={ref}>
          <motion.div
            variants={enterexit}
            initial="hidden"
            animate={inView ? "enter" : "hidden"}
            exit="exit"
            className="footer"
          >
            <div className="footer-text">made by yours truly,</div>
            <Link to="/" className="logo">
              r
            </Link>
          </motion.div>
        </div>
      )}
    </InView>
  )
}

export default Footer
