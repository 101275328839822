import React from "react";
import { InView } from "react-intersection-observer";
import { motion } from "framer-motion";
import Header from "./Header";
import Footer from "./Footer";
import Title from "./Title";
import Highlight from "./Highlight";
import Textbox from "./Textbox";
import Image from "./Image";
import Phone from "./Phone";
import MoreProjects from "./MoreProjects";
import "../css/App.scss";
import koodosCard00 from "../images/koodoscase_card_00.png";
import koodosCard01 from "../images/koodoscase_card_01.png";
import koodosCard02 from "../images/koodoscase_card_02.png";
import heartIcon from "../images/koodoscase_heart_icon.svg";
import giveKoodosTip from "../images/koodoscase_givekoodos_tip.png";
import giveKoodosModal from "../images/koodoscase_givekoodos_modal.png";
import giveKoodosMessages from "../images/koodoscase_givekoodos_messages.png";
import stampsAnimation from "../images/koodoscase_stamps_animation.gif";
import stampsList from "../images/koodoscase_stamps_list.png";
import notesInput from "../images/koodoscase_notes_input.png";
import notesReply from "../images/koodoscase_notes_reply.png";

function Koodos({ location }) {
  return (
    <div className="project-page" id="koodoscase-page">
      <Header />
      <Title
        section="project"
        text="koodos: case study"
        location={location}
        viewed
      />

      <div className="sub-container centered-h section">
        <div className="sub-container buffered-h">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="blurb" delayN={0} topY={0} viewed={inView}>
                  A system to facilitate positive interactions between users in
                  an online space. A safer, more meaningful alternative to likes
                  and comments on social media.
                </Textbox>

                <div className="roles-container">
                  <Highlight section="role" delayN={1} topY={0} viewed={inView}>
                    product design
                  </Highlight>
                  <Highlight section="role" delayN={2} topY={0} viewed={inView}>
                    ui/ux design
                  </Highlight>
                </div>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container buffered-h">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <div className="sub-container column">
                  <Highlight
                    section="label"
                    delayN={2}
                    topY={0}
                    viewed={inView}
                  >
                    date
                  </Highlight>
                  <Textbox
                    id="metadata right-align"
                    delayN={1}
                    topY={-20}
                    viewed={inView}
                  >
                    Spring 2022
                  </Textbox>
                </div>
              </div>
            )}
          </InView>
        </div>
      </div>
      <InView triggerOnce>
        {({ inView, ref }) => (
          <div ref={ref}>
            <Title
              section="project-section"
              text="the context"
              delayN={0}
              viewed={inView}
            />
          </div>
        )}
      </InView>

      <div className="sub-container section">
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight section="banner" delayN={0} topY={0} viewed={inView}>
                  a vulnerable space
                </Highlight>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="big" delayN={0} topY={0} viewed={inView}>
                  At the surface, Koodos is a tool for users to share their
                  favorite songs.
                  <br />
                  <br />
                  At its core, Koodos is a <b>highly emotional space,</b> where
                  users express and connect over sentimental value toward the
                  songs that mean something to them.
                  <br /> <br />
                  Users on Koodos often share emotionally expressive, vulnerable
                  thoughts inspired by music.
                </Textbox>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container centered-h">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref} className="sub-container buffered-v">
                <Image
                  source={koodosCard02}
                  id="koodos-card"
                  delayN={0}
                  topY={0}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref} className="sub-container buffered-v">
                <Image
                  source={koodosCard01}
                  id="koodos-card"
                  delayN={0}
                  topY={0}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
        </div>
      </div>

      <div className="sub-container section">
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight section="banner" delayN={0} topY={0} viewed={inView}>
                  psychological safety
                </Highlight>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="big" delayN={0} topY={0} viewed={inView}>
                  At the same time, Koodos is a <b>social</b> product where
                  users can interact with one another.
                  <br />
                  <br />
                  This poses the risk that negative interactions between users
                  may compromise Koodos as a safe space for free expression.
                  This is especially true for Koodos because the majority of
                  users are young people who go by pseudonyms, and anonymity can
                  stimulate irresponsible behavior.
                  <br />
                  <br />
                  Therefore, <b>psychological safety</b> is a core value that
                  must be taken into special consideration when designing social
                  features for the product.
                  <br />
                  <br />
                  It is only when this core value is upheld that users can fully
                  enjoy the value of our product.
                </Textbox>
              </div>
            )}
          </InView>
        </div>
      </div>
      <div className="sub-container section">
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight section="banner" delayN={0} topY={0} viewed={inView}>
                  conversation
                </Highlight>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="big" delayN={0} topY={0} viewed={inView}>
                  The focus of this case study is{" "}
                  <b>conversation between users</b>.
                  <br />
                  <br />
                  It is a feature that can carry many risks, but the potential
                  benefits are immense.
                </Textbox>
              </div>
            )}
          </InView>
        </div>
      </div>

      <InView triggerOnce>
        {({ inView, ref }) => (
          <div ref={ref}>
            <Title
              section="project-section"
              text="the approach"
              delayN={0}
              viewed={inView}
            />
          </div>
        )}
      </InView>

      <div className="sub-container section">
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight section="banner" delayN={0} topY={0} viewed={inView}>
                  barriers
                </Highlight>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="big" delayN={0} topY={0} viewed={inView}>
                  Taking the above risks into account, I used what I will refer
                  to as the <b>barriers method</b> to encourage safe and genuine
                  connection.
                  <br />
                  <br />
                  <b>Part 1: Physical barrier</b>. To unlock the ability to
                  converse with another user, one must first take action to
                  prove that they come with good intentions.
                  <br />
                  <br />
                  <b>Part 2: Psychological barrier</b>. Messages are framed as a
                  personal note, rather than a publicly shared opinion.
                  <br />
                  <br />
                  This method forces users to be <b>thoughtful</b> and{" "}
                  <b>well-intentioned</b> when conversing with others in the
                  online space. There is little room for mindlessness and
                  hatefulness.
                </Textbox>
              </div>
            )}
          </InView>
        </div>
      </div>
      <InView triggerOnce>
        {({ inView, ref }) => (
          <div ref={ref}>
            <Title
              section="project-section"
              text="the experiment"
              delayN={0}
              viewed={inView}
            />
          </div>
        )}
      </InView>
      <div className="sub-container section">
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight section="banner" delayN={0} topY={0} viewed={inView}>
                  experiment design
                </Highlight>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="big" delayN={0} topY={0} viewed={inView}>
                  I researched the effectiveness of the <b>barriers method</b>{" "}
                  in creating a safe, social online space through the launch of
                  an experimental feature called <b>"give koodos"</b>.
                  <br />
                  <br />
                  For a limited time, <b>after a user likes a post</b> on
                  Koodos, they would be shown a tooltip that gives them the
                  option to “give koodos” to the creator of the post by sending
                  them a personal message.
                  <br />
                  <br />
                  The <b>physical barrier</b> was the action of liking the post,
                  and the <b>psychological barrier</b> was the delivery to the
                  receiver's personal inbox.
                  <br />
                  <br />
                  We had a manual approval process to moderate the messages
                  before relaying them to the receiver.
                </Textbox>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container buffered-v centered-h centered-v">
          <div className="sub-container">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref} className="sub-container buffered-h centered-h">
                  <Image
                    source={koodosCard00}
                    id="koodos-card"
                    delayN={0}
                    topY={0}
                    viewed={inView}
                  />
                  <motion.div
                    transition={{
                      duration: 0.5,
                      delay: 1,
                      type: "spring",
                    }}
                    animate={{ scale: [1, 1.2, 1] }}
                    viewed={inView}
                    className="koodoscase-hearticon-container"
                  >
                    <Image
                      source={heartIcon}
                      id="koodoscase-heart-icon"
                      delayN={0}
                      topY={0}
                      viewed={inView}
                    />
                  </motion.div>
                  <motion.div
                    transition={{
                      times: [0, 0.125, 0.25, 0.375, 0.5],
                      duration: 1.8,
                      delay: 1.4,
                      ease: "easeInOut",
                      repeat: Infinity,
                      repeatType: "mirror",
                    }}
                    animate={{ y: [0, -8, 0, -8, 0] }}
                    viewed={inView}
                    className="koodoscase-tooltip-container"
                  >
                    <Image
                      source={giveKoodosTip}
                      id="koodoscase-tooltip"
                      delayN={0}
                      topY={0}
                      viewed={inView}
                    />
                  </motion.div>
                </div>
              )}
            </InView>
          </div>
          <div className="sub-container buffered-h buffered-v">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref} className="sub-container">
                  <Image
                    source={giveKoodosModal}
                    id="koodoscase-modal"
                    delayN={0}
                    topY={0}
                    viewed={inView}
                  />
                </div>
              )}
            </InView>
          </div>
        </div>
      </div>
      <div className="sub-container section">
        <div className="sub-container buffered-v centered-h centered-v">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight section="banner" delayN={0} topY={0} viewed={inView}>
                  learnings
                </Highlight>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container buffered-v centered-h centered-v">
          <div className="sub-container">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Textbox id="medium" delayN={0} topY={0} viewed={inView}>
                    Nearly <b>a thousand</b> “give koodos” messages were sent
                    during the experimental period.
                    <br />
                    <br />
                    Of those thousand, there was{" "}
                    <b>not a single malicious message</b>.
                    <br />
                    <br />
                    Through this experiment, I built confidence in proceeding to
                    use the “barriers” method for a comment-like feature on
                    koodos without the need for manual screening of messages.
                  </Textbox>
                </div>
              )}
            </InView>
          </div>
          <div className="sub-container">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref} className="sub-container buffered-v">
                  <Image
                    source={giveKoodosMessages}
                    id="koodoscase-messages"
                    delayN={0}
                    topY={0}
                    viewed={inView}
                  />
                </div>
              )}
            </InView>
          </div>
        </div>
      </div>

      <InView triggerOnce>
        {({ inView, ref }) => (
          <div ref={ref}>
            <Title
              section="project-section"
              text="the product"
              delayN={0}
              viewed={inView}
            />
          </div>
        )}
      </InView>

      <div className="sub-container section">
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight section="banner" delayN={0} topY={0} viewed={inView}>
                  stamps
                </Highlight>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container centered-h">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref} className="sub-container buffered-v centered-v">
                <Textbox id="medium" delayN={0} topY={0} viewed={inView}>
                  Stamps function like “likes” on social media, but they{" "}
                  <b>feel</b> more like active stamps of approval.
                  <br />
                  <br />
                  It is only by giving your stamp of approval that you can
                  unlock the ability to send a nice note to the post creator.
                  This <b>physical barrier</b> to textual communication serves
                  as a filter for hateful messages, and primes the user toward
                  positive behavior.
                  <br />
                  <br />
                  The total number of stamps on a post are not displayed, in
                  order to discourage comparison.
                </Textbox>
              </div>
            )}
          </InView>
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref} className="sub-container buffered-v">
                <Phone
                  source={stampsAnimation}
                  id="koodoscase-phone"
                  delayN={0}
                  topY={0}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container centered-h">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref} className="sub-container buffered-v">
                <Phone
                  source={stampsList}
                  id="koodoscase-phone"
                  delayN={0}
                  topY={0}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref} className="sub-container buffered-v centered-v">
                <Textbox id="medium" delayN={0} topY={0} viewed={inView}>
                  A user can view a list of all stamps given to a post. Similar
                  to signatures on a petition, this is a list that indicates who
                  supports this post.
                </Textbox>
              </div>
            )}
          </InView>
        </div>
      </div>

      <div className="sub-container section">
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight section="banner" delayN={0} topY={0} viewed={inView}>
                  notes
                </Highlight>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container centered-h">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref} className="sub-container buffered-v centered-v">
                <Textbox id="medium" delayN={0} topY={0} viewed={inView}>
                  Notes function like “comments” on social media, but they{" "}
                  <b>feel</b> more like personal messages to the user.
                  <br />
                  <br />
                  This slight nuance in framing goes a long way as a{" "}
                  <b>psychological barrier</b> to dissuade mindless hate and
                  encourage thoughtfulness.
                  <br />
                  <br />
                  By default, notes on koodos posts are <b>private</b>. This
                  means only the post creator and the sender can view the note.
                </Textbox>
              </div>
            )}
          </InView>
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref} className="sub-container buffered-v">
                <Phone
                  source={notesInput}
                  id="koodoscase-phone"
                  delayN={0}
                  topY={0}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container centered-h">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref} className="sub-container buffered-v">
                <Phone
                  source={notesReply}
                  id="koodoscase-phone"
                  delayN={0}
                  topY={0}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref} className="sub-container buffered-v centered-v">
                <Textbox id="medium" delayN={0} topY={0} viewed={inView}>
                  The creator has the option to make a note{" "}
                  <b>publicly viewable</b> on their post. In this way, they can
                  flaunt the nice messages they&apos;ve received.
                  <br />
                  <br />
                  The creator also has the option of <b>replying</b> to a note
                  that was sent to them. In this way, they can form a reciprocal
                  relationship with the sender.
                </Textbox>
              </div>
            )}
          </InView>
        </div>
      </div>

      <MoreProjects hide="koodoscase" />

      <Footer />
    </div>
  );
}

export default Koodos;
