import React from "react"
import { InView } from "react-intersection-observer"
import Header from "./Header"
import Footer from "./Footer"
import Title from "./Title"
import Highlight from "./Highlight"
import Textbox from "./Textbox"
import MoreProjects from "./MoreProjects"

function Rivet({ location }) {
  return (
    <div className="project-page" id="rivet-page">
      <Header />
      <Title section="project" text="rivet" location={location} viewed />

      <div className="content-container overview">
        <div className="sub-container column overview">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="blurb" delayN={0} topY={0} viewed={inView}>
                  Rivet is an early-stage startup dedicated to helping music
                  artists better engage with and understand their fanbase. As
                  their first and sole designer, I&apos;ve been doing everything
                  related to design, from branding, UI/UX, and more.
                </Textbox>

                <div className="roles-container">
                  <Highlight section="role" delayN={1} topY={0} viewed={inView}>
                    ui/ux design
                  </Highlight>
                  <Highlight section="role" delayN={1} topY={0} viewed={inView}>
                    product design
                  </Highlight>
                  <Highlight section="role" delayN={2} topY={0} viewed={inView}>
                    branding
                  </Highlight>
                </div>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container column overview">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <div className="sub-container column overview">
                  <Highlight
                    section="label"
                    delayN={2}
                    topY={0}
                    viewed={inView}
                  >
                    date
                  </Highlight>
                  <Textbox
                    id="metadata right-align"
                    delayN={1}
                    topY={-20}
                    viewed={inView}
                  >
                    Winter 2021 - Current
                  </Textbox>
                </div>
              </div>
            )}
          </InView>
        </div>
      </div>
      <div>
        <div className="sub-container buffered">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="medium" delayN={0} topY={0} viewed={inView}>
                  I&apos;m working on some super-secret, classified stuff right
                  now... check back for updates!
                </Textbox>
              </div>
            )}
          </InView>
        </div>
      </div>
      <MoreProjects hide="rivet" />
      <Footer />
    </div>
  )
}

export default Rivet
