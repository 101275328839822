import React from "react";
import { InView } from "react-intersection-observer";
import Header from "./Header";
import Footer from "./Footer";
import Title from "./Title";
import Highlight from "./Highlight";
import Textbox from "./Textbox";
import Image from "./Image";
import Playable from "./Playable";
import MoreProjects from "./MoreProjects";
import "../css/App.scss";
import chineseCharacter from "../images/guitar_chinese.png";
import stringsInspo from "../images/guitar_strings_inspo.png";
import letterPlacement from "../images/guitar_placement.png";
import letterG from "../images/guitar_g.png";
import letterForm from "../images/guitar_letterform.gif";

function Guitar({ location }) {
  return (
    <div className="project-page" id="guitar-page">
      <Header />
      <Title section="project" text="guitar" location={location} viewed />

      <div className="content-container overview">
        <div className="sub-container column overview">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="blurb" delayN={0} topY={0} viewed={inView}>
                  More than just a font. A typeface that can be experienced in
                  three different ways.
                </Textbox>

                <div className="roles-container">
                  <Highlight section="role" delayN={1} topY={0} viewed={inView}>
                    experience design
                  </Highlight>
                  <Highlight section="role" delayN={2} topY={0} viewed={inView}>
                    type design
                  </Highlight>
                </div>

                <Highlight
                  section="label overview-link"
                  delayN={4}
                  topY={-100}
                  viewed={inView}
                >
                  <a
                    href={`${process.env.PUBLIC_URL}/guitar/guitar.html`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    check it out
                  </a>
                </Highlight>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container column overview">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <div className="sub-container column overview">
                  <Highlight
                    section="label"
                    delayN={2}
                    topY={0}
                    viewed={inView}
                  >
                    date
                  </Highlight>
                  <Textbox
                    id="metadata right-align"
                    delayN={1}
                    topY={-20}
                    viewed={inView}
                  >
                    Fall 2019
                  </Textbox>
                </div>
              </div>
            )}
          </InView>
        </div>
      </div>

      <InView triggerOnce>
        {({ inView, ref }) => (
          <div ref={ref}>
            <Title
              section="project-section"
              text="the inspiration"
              delayN={0}
              viewed={inView}
            />
          </div>
        )}
      </InView>

      <div className="sub-container section">
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight section="banner" delayN={0} topY={0} viewed={inView}>
                  experiential font
                </Highlight>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="big" delayN={0} topY={0} viewed={inView}>
                  I wanted to create a playful font that is not only meant to be
                  read and seen, but is meant to be <b>experienced</b>.
                  <br />
                  <br />I imagined a font that contains meaning within each
                  stroke of a letter, like Chinese characters.
                </Textbox>
              </div>
            )}
          </InView>
        </div>
        <InView triggerOnce>
          {({ inView, ref }) => (
            <div ref={ref} className="sub-container caption-image buffered-v">
              <Image
                source={chineseCharacter}
                id="guitar-chinese"
                delayN={0}
                topY={0}
                viewed={inView}
              />
              <div className="caption-container">
                <Highlight
                  section="image-label"
                  delayN={1}
                  topY={0}
                  viewed={inView}
                >
                  <a
                    href="https://studycli.org/chinese-characters/learn-chinese-characters/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    studycli.org
                  </a>
                </Highlight>
              </div>
            </div>
          )}
        </InView>
      </div>

      <div className="sub-container section">
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight section="banner" delayN={0} topY={0} viewed={inView}>
                  strumming and writing
                </Highlight>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="big" delayN={0} topY={0} viewed={inView}>
                  Fontface design requires first creating a{" "}
                  <b>rigorous system</b> upon which the letterform are built.
                  <br />
                  <br />
                  As inspiration for this system, I looked to my guitar.
                </Textbox>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container centered-h centered-v">
          <div className="sub-container buffered-v">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Textbox id="medium" delayN={0} topY={0} viewed={inView}>
                    The strings of a guitar reminded me of lines on a piece of
                    ruled paper. Then, the act of strumming is like{" "}
                    <b>writing imaginary letters</b> on the strings of a guitar.
                    <br />
                    <br />
                    This idea was adequate as a foundation for my playful, yet
                    rigorous fontface design system.
                  </Textbox>
                </div>
              )}
            </InView>
          </div>
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref} className="sub-container buffered-v">
                <Image
                  source={stringsInspo}
                  id="guitar-strings-inspo"
                  delayN={0}
                  topY={0}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
        </div>
      </div>

      <InView triggerOnce>
        {({ inView, ref }) => (
          <div ref={ref}>
            <Title
              section="project-section"
              text="the product"
              delayN={0}
              viewed={inView}
            />
          </div>
        )}
      </InView>

      <div className="sub-container section">
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight section="banner" delayN={0} topY={0} viewed={inView}>
                  type as instructions
                </Highlight>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="big" delayN={0} topY={0} viewed={inView}>
                  The instructional form uses a grid that represents guitar
                  strings. <b>Arrows</b> indicate direction of strumming, and{" "}
                  <b>numbers</b> mark the order of the strums. <br />
                  <br />
                  Each strumming pattern spans four strings of the guitar, with
                  exceptions made for certain letters to improve legibility.{" "}
                </Textbox>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Image
                  source={letterG}
                  id="guitar-g"
                  delayN={0}
                  topY={0}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="big" delayN={0} topY={0} viewed={inView}>
                  Vowels are placed physically higher on the guitar than
                  consonants.
                </Textbox>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Image
                  source={letterPlacement}
                  id="guitar-placement"
                  delayN={0}
                  topY={0}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
        </div>
      </div>

      <div className="sub-container section">
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight section="banner" delayN={0} topY={0} viewed={inView}>
                  type as letterforms
                </Highlight>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container centered-h centered-v">
          <div className="sub-container buffered-v">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <Textbox id="medium" delayN={0} topY={0} viewed={inView}>
                    The usable letterforms are derived from the strumming
                    patterns of the letters, with <b>line weights</b> rather than
                    arrows indicating direction of each strum.
                  </Textbox>
                </div>
              )}
            </InView>
          </div>
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref} className="sub-container buffered-v">
                <Image
                  source={letterForm}
                  id="guitar-letterform"
                  delayN={0}
                  topY={0}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
        </div>
      </div>

      <div className="sub-container section">
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Highlight section="banner" delayN={0} topY={0} viewed={inView}>
                  type as sound
                </Highlight>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container centered-h centered-v">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref} className="sub-container buffered-v centered-v">
                <Playable
                  source={`${process.env.PUBLIC_URL}/assets/guitar_g.mp3`}
                  section="guitar-g"
                  delayN={0}
                  topY={0}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref} className="sub-container buffered-v centered-v">
                <Textbox id="medium" delayN={0} topY={0} viewed={inView}>
                  The examples in this project play the strumming patterns on{" "}
                  <b>open strings</b>.
                  <br />
                  <br />
                  Since the font only specifies strumming pattern, it can be
                  played to any chord, or even a sequence of chords. Each word
                  or sentence will produce a different rhythm for the same song.
                </Textbox>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container buffered-v spaced">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Textbox id="big" delayN={0} topY={0} viewed={inView}>
                  Because vowels are placed physically higher on the guitar
                  strings than consonants, they have a deeper sound.
                  <br />
                  <br />
                  This difference in sound reflects the difference that exists
                  in how vowels and consonant sounds are produced in the mouth.
                  It also creates some variance in pitch when listening to a
                  word in guitar.
                  <br />
                  <br />
                  <b>Click the examples below</b> to compare vowels and
                  consonants.
                </Textbox>
              </div>
            )}
          </InView>
        </div>
        <div className="sub-container centered-h">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref} className="sub-container buffered-v buffered-h">
                <Playable
                  source={`${process.env.PUBLIC_URL}/assets/guitar_vowels.mp3`}
                  section="guitar-sound guitar-vowels"
                  delayN={0}
                  topY={0}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
          <InView triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref} className="sub-container buffered-v huffered-h">
                <Playable
                  source={`${process.env.PUBLIC_URL}/assets/guitar_consonants.mp3`}
                  section="guitar-sound guitar-consonants"
                  delayN={0}
                  topY={0}
                  viewed={inView}
                />
              </div>
            )}
          </InView>
        </div>
      </div>

      <MoreProjects hide="guitar" />
      <Footer />
    </div>
  );
}

export default Guitar;
