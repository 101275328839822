import React from "react";
import { motion } from "framer-motion";
import "../css/App.scss";

function playAudio(audioSource) {
  var audio = new Audio();
  audio.src = audioSource
  audio.play();
}

function Playable({ section, source, delayN, viewed }) {
  const enterexit = {
    hidden: {
      left: -50,
      opacity: 0,
    },
    enter: {
      left: 0,
      opacity: 1,
      transition: {
        duration: 0.4,
        delay: delayN * 0.4,
      },
    },
    exit: {
      left: -50,
      opacity: 0,
      transition: {
        duration: 0.3,
      },
    },
  };

  return (
    <motion.div
      custom
      variants={enterexit}
      initial="hidden"
      animate={viewed ? "enter" : "hidden"}
      exit="exit"
      className={`playable ${section}`}
      onClick={() => playAudio(source)}
    >
      <div className="playable-label" >
        click to play
      </div>
    </motion.div>
  );
}

export default Playable;
